import "./footer.css";

const Footer = () => {
  return (
    <div className="footer">
      AJ Renovations 2024 © Copyright - All Rights Reserved
    </div>
  );
};

export default Footer;
